import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { Link } from "gatsby"

const HeroText = styled.h1`
  font-family: "Montserrat";
  font-size: 5rem;
  color: #fff;
  font-weight: 900;
  text-transform: uppercase;
  text-shadow: 0.5px 0.5px 0.5px #333;
  line-height: 0.8;
  margin: 4rem auto 1rem;
  text-align: center;
  @media (max-width: 640px) {
    font-size: 2.8rem;
    margin: 0;
  }
`

const SecondaryText = styled.h2`
  font-family: "Montserrat";
  font-size: 2rem;
  color: #fff;
  font-weight: 900;
  text-transform: uppercase;
  padding-top: 0;
  text-shadow: 0.5px 0.5px 0.5px #333;
  line-height: 0.8;
  text-align: center;
  @media (max-width: 640px) {
    font-size: 1rem;
    margin-top: 0.5rem;
  }
`

const HeroImageLayout = ({
  ImgSrc,
  MainText,
  SecondText,
  FirstButtonLocation,
  FirstButtonText,
  SecondButtonLocation,
  SecondButtonText,
}) => (
  <div
    css={css`
      position: relative;
      background-color: #312e2e;
      background-image: url(${ImgSrc});
      background-size: cover;
      background-position: center top;
      background-repeat: no-repeat;
      @media (max-width: 640px) {
        height: 400px;
      }
    `}
  >
    <div
      css={css`
        position: relative;
        height: 600px;
        margin: 2rem auto 4rem;
        padding-top: 200px;
        max-width: 90vw;
        width: 1050px;
        @media (max-width: 640px) {
          padding-top: 80px;
          height: 400px;
        }
      `}
    >
      <HeroText>{MainText}</HeroText>
      <SecondaryText>{SecondText}</SecondaryText>
      <div
        css={css`
        display: flex;
        justify-content: center;
        align-items; center;
        @media (max-width: 640px) {
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      `}
      >
        <Link
          css={css`
            text-decoration: none;
            color: #312e2e;
            background: #fff;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
            font-family: Montserrat;
            font-weight: 600;
            border-radius: 8px;
            text-transform: uppercase;
            margin: 3rem 1rem;
            padding: 0.8rem 3rem;
            font-size: 1.2rem;
            border: 0;
            cursor: pointer;
            &:hover {
              background: #f07525;
              color: #fff;
            }
            @media (max-width: 640px) {
              margin-top: 3rem;
              margin-bottom: 0;
              width: 100%;
              text-align: center;
            }
            :empty {
              display: none;
            }
          `}
          to={FirstButtonLocation}
        >
          {FirstButtonText}
        </Link>
        <Link
          css={css`
            text-decoration: none;
            color: #fff;
            background: #f07525;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
            font-family: Montserrat;
            font-weight: 600;
            border-radius: 8px;
            text-transform: uppercase;
            margin: 3rem 1rem;
            padding: 0.8rem 3rem;
            font-size: 1.2rem;
            border: 0;
            cursor: pointer;
            &:hover {
              background: #fff;
              color: #312e2e;
            }
            @media (max-width: 640px) {
              margin: 0;
              width: 100%;
              text-align: center;
              margin-top: 1rem;
            }
            :empty {
              display: none;
            }
          `}
          to={SecondButtonLocation}
        >
          {SecondButtonText}
        </Link>
      </div>
    </div>
  </div>
)

export default HeroImageLayout
